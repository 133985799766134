import React from 'react'
import { navigate } from 'gatsby'

// Style
import {
  wrapper,
  pageStyle,
  titleStyle,
  background,
  numberStyle,
  descriptionStyle,
} from './styles.module.scss'
import FICCO2022Button from '../../atoms/FICCO22Button'

const NotFound = () => {
  return (
    <>
      <div className={`block-wrapper ${background}`}>
        <div className={`container ${wrapper} font-subscriber`}>
          <h2 className={titleStyle}>Lo sentimos</h2>
          <h3 className={descriptionStyle}>Algo ha salido mal</h3>
          <p className={numberStyle}>404</p>
          <FICCO2022Button
            text={'VOLVER AL HOME'}
            handleClick={() => {
              navigate('/')
            }}
          />
        </div>
      </div>
    </>
  )
}

export default NotFound
